html {
    font-family: Lato, sans-serif;
    font-size: 16px;
}

body {
    padding: 0;
    margin: 0;
    background: #F4F6F9;
}

#root {
    min-height: 100vh;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

pre {
    display: inline;
}
